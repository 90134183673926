import React from 'react';
import { LinkMobileNav } from '../../atoms/Link';
import { NavMenuItem } from '../../atoms/NavMenuItem';
import { ChildrenMenuType } from '../TopNavigation';

interface ChildrenMobileMenuProps {
  handleMenuOpen: () => void;
  selectedTab: ChildrenMenuType[];
}

export const ChildrenMobileMenu = ({
  handleMenuOpen,
  selectedTab,
}: ChildrenMobileMenuProps): JSX.Element => (
  <>
    {selectedTab.map((tab) => (
      <NavMenuItem key={tab.label} onClick={handleMenuOpen}>
        <LinkMobileNav
          activeClassName="active"
          partiallyActive={true}
          to={tab.path}
        >
          {tab.label}
        </LinkMobileNav>
      </NavMenuItem>
    ))}
  </>
);
