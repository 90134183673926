import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import NavLogoForDarkBg from '../../assets/images/NaturalAntibodyLogoWithTextForDarkBg.inline.svg';
import NavLogoForLightBg from '../../assets/images/NaturalAntibodyLogoWithTextForLightBg.inline.svg';
import NavLogoSignature from '../../assets/images/NaturalAntibodyLogo.svg';
import routes from '../../routes';
import { LinkColorProps, LinkColorStyles } from './Link';

interface NavLogoWrapperProps {
  open: boolean;
  isScrolled: boolean;
  onlyLogo?: boolean;
}

const NavLogoWrapper = ({
  open,
  isScrolled,
  onlyLogo,
}: NavLogoWrapperProps): JSX.Element => {
  return (
    <StyledNavLogoWrapper open={open} to={routes.home}>
      {onlyLogo ? (
        <LogoStyled src={NavLogoSignature} />
      ) : (
        <>{isScrolled ? <NavLogoForLightBg /> : <NavLogoForDarkBg />}</>
      )}
    </StyledNavLogoWrapper>
  );
};

const StyledNavLogoWrapper = styled(Link).attrs({
  $focusBg: 'focusSecondary',
})<
  LinkColorProps & {
    open: boolean;
  }
>`
  ${LinkColorStyles}

  z-index: ${({ theme }) => theme.layers.navigation + 1};

  ${({ open }) =>
    open &&
    css`
      filter: grayscale(0) brightness(1) !important;
    `}
`;

const LogoStyled = styled.img``;

export default NavLogoWrapper;
