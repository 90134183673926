import {
  createGlobalStyle,
  css,
  DefaultTheme,
  FlattenSimpleInterpolation,
  keyframes,
  Keyframes,
} from 'styled-components';
import GreycliffCFBold from '../assets/fonts/GreycliffCF-Bold.otf';
import GreycliffCFMedium from '../assets/fonts/GreycliffCF-Medium.otf';

const GlobalStyles = createGlobalStyle<{
  textColor: keyof DefaultTheme['colors']['texts'];
  backgroundColor: keyof DefaultTheme['colors']['backgrounds'];
}>`
  * {
    box-sizing: border-box;
  }

  @font-face {
    font-family: "Greycliff CF";
    font-display: swap;
    src: url(${GreycliffCFMedium});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Greycliff CF";
    font-display: swap;
    src: url(${GreycliffCFBold});
    font-weight: 700;
    font-style: normal;
  }

  html {
    font-family: 'Greycliff CF';
    font-size: 14px;
    color: ${({ theme, textColor }) => theme.colors.texts[textColor]};
    background: ${({ theme, backgroundColor }) =>
      theme.colors.backgrounds[backgroundColor]};

	  scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce) {
      scroll-behavior: auto;
    }
  }

  body.menu-open {
    overflow: hidden;
  }

  a {
    color: inherit;
  }

  a, button {
    scroll-margin-top: 80px;
  }

  // https://webaim.org/techniques/css/invisiblecontent/#techniques
  .sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
`;

export const ScrollAnimation = keyframes`
	0% {transform: translateY(-8px); opacity: 0;}
	100% {transform: translateY(8px); opacity: 1;}
`;

export const animation = (
  delay: number,
  duration: number,
  animation: Keyframes,
): FlattenSimpleInterpolation => {
  return css`
    animation-delay: ${delay}s;
    animation-duration: ${duration}s;
    animation-name: ${animation};
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    stroke: black;
  `;
};

export default GlobalStyles;
