import React from 'react';
import styled from 'styled-components';

const MenuWrapper = styled.div`
  --bar-width: 20px;
  --bar-height: 1.5px;
  --bar-spacing: 8px;
  padding: 10px 0;
  z-index: ${({ theme }) => theme.layers.navigation + 1};
  cursor: pointer;
  .hamburger-menu,
  .hamburger-menu::before {
    width: var(--bar-width);
    height: var(--bar-height);
    border-radius: 1px;
  }
  .hamburger-menu::after {
    width: calc(var(--bar-width) - 10px);
    left: 0;
    height: var(--bar-height);
    border-radius: 1px;
  }
  .hamburger-menu.scrolled,
  .hamburger-menu.scrolled::before,
  .hamburger-menu.scrolled::after {
    background: ${({ theme }) => theme.colors.backgrounds.primaryAccented};
  }
  .hamburger-menu {
    position: relative;
    background: ${({ theme }) => theme.colors.backgrounds.secondary};
    &.animate {
      transition: all 0ms 0.2s;
      background: transparent;
      :after {
        width: var(--bar-width);
        background: ${({ theme }) => theme.colors.backgrounds.secondary};
      }
      :before {
        background: ${({ theme }) => theme.colors.backgrounds.secondary};
      }
    }
  }
  .hamburger-menu::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: var(--bar-spacing);
    background: ${({ theme }) => theme.colors.backgrounds.secondary};
    transition: bottom 0.15s 0.15s ease-in-out, transform 0.15s ease-in-out;
  }
  .hamburger-menu::after {
    content: '';
    position: absolute;
    right: 0;
    top: var(--bar-spacing);
    background: ${({ theme }) => theme.colors.backgrounds.secondary};
    transition: top 0.15s 0.15s ease-in-out, transform 0.15s ease-in-out;
  }
  .hamburger-menu.animate::after {
    top: 0;
    transform: rotate(45deg);
    transition: top 0.15s ease-in-out, transform 0.15s 0.15s ease-in-out;
  }
  .hamburger-menu.animate::before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 0.15s ease-in-out, transform 0.15s 0.15s ease-in-out;
  }
`;

interface IProps {
  open: boolean;
  scrolled: boolean;
  handleMenuOpen: () => void;
}

const HamburgerMenu = ({
  open,
  scrolled,
  handleMenuOpen,
}: IProps): JSX.Element => {
  return (
    <MenuWrapper onClick={handleMenuOpen}>
      <div
        className={`hamburger-menu ${open ? 'open animate' : ''}
         ${scrolled ? 'scrolled' : ''}`}
      />
    </MenuWrapper>
  );
};

export default HamburgerMenu;
