import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import noiseBackground from '../../assets/images/noiseBackground.png';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import routes from '../../routes';
import {
  forScreenAtMost1200pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';
import { CraftedByMitsWrapper } from '../atoms/CraftedByMits';
import FooterMenu from '../atoms/FooterMenu';
import Legal from '../atoms/Legal';
import { RegularLink } from '../atoms/Link';
import ContactInvitationBox from './footer/ContactInvitationBox';
import BookDemo from './footer/BookDemo';
import SocialSection from './footer/SocialSection';
import { MainContentColumnWrapper } from './Layout';
import UEFlag from './navigation/UEFlag';
import NgsInvitationToContact from './footer/NgsInvitationToContact';

const footerMenuItems = [
  {
    label: 'Antibody Database',
    path: routes.antibodyKnowledgeGraph,
  },
  {
    label: 'Antibody Analytics',
    path: routes.antibodyAnalytics,
  },
  {
    label: 'About us',
    path: routes.aboutUs,
  },
  {
    label: 'Resources',
    path: routes.resourcesYear,
  },
  {
    label: 'Newsroom',
    path: routes.newsroom,
  },
  {
    label: 'Careers',
    path: routes.careers,
  },
  // {
  //   label: 'Grants',
  //   path: routes.grants,
  //   icon: <UEFlag height="auto" viewBox="0 0 80 55" />,
  // },
];

export enum FooterBoxContent {
  None = 'None',
  ContactInvitation = 'ContactInvitation',
  NgsInvitationToContact = 'NgsInvitationToContact',
  BookDemo = 'BookDemo',
  NotFound = 'NotFound',
  // Newsletter = 'Newsletter',
}

interface FooterProps {
  boxContent: FooterBoxContent;
}

const Footer = ({ boxContent }: FooterProps): JSX.Element => (
  <Wrapper withoutBackground={boxContent === FooterBoxContent.NotFound}>
    {boxContent === FooterBoxContent.ContactInvitation && (
      <ContactInvitationBox />
    )}
    {boxContent === FooterBoxContent.NgsInvitationToContact && (
      <NgsInvitationToContact />
    )}
    {boxContent === FooterBoxContent.BookDemo && <BookDemo />}
    <MainFooterContent hasBoxContent={boxContent !== FooterBoxContent.None} />
  </Wrapper>
);

interface MainFooterContentProps {
  hasBoxContent: boolean;
}

const MainFooterContent = ({
  hasBoxContent,
}: MainFooterContentProps): JSX.Element => {
  const theme = useContext(ThemeContext);
  const narrowLayout = useMaxWidthBreakpoint(680);

  return (
    <MainFooterWrapper hasBoxContent={hasBoxContent}>
      <MainContentColumnWrapper>
        <FooterFirstLine>
          <FooterMenu
            linkComponent={FooterMenuLink}
            links={footerMenuItems}
            separatorColors={{
              primary: theme.colors.accents.secondary,
              secondary: theme.colors.accents.secondaryPair,
            }}
          />
          <SocialSection verticalLayout={narrowLayout} />
        </FooterFirstLine>
        <FooterSecondLine>
          <div>
            <Legal
              authorRoute={routes.home}
              cookiesPolicyRoute={routes.cookiesPolicy}
              lessVisibleLinkColor="delicate"
              moreVisibleLinkColor="primary"
              privacyPolicyRoute={routes.privacyPolicy}
              verticalLayout={narrowLayout}
            />
          </div>
          <FooterMenuLink to={routes.grants}>
            <UEFlag height="auto" viewBox="0 0 60 75" />
          </FooterMenuLink>
        </FooterSecondLine>
      </MainContentColumnWrapper>
    </MainFooterWrapper>
  );
};

const Wrapper = styled.footer<{ withoutBackground: boolean }>`
  position: relative;
  ${({ theme, withoutBackground }) =>
    !withoutBackground &&
    css`
      background-color: ${theme.colors.backgrounds.footer};
      background-image: url('${noiseBackground}');
    `}

  background-blend-mode: color-dodge;
  padding-bottom: 20px;
  line-height: 20px;
`;

const MainFooterWrapper = styled.div<{ hasBoxContent: boolean }>`
  ${({ hasBoxContent }) =>
    hasBoxContent
      ? css`
          padding-top: 142px;
        `
      : css`
          padding-top: 50px;
        `}
`;

const FooterFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.footer.firstLine};

  ${forScreenAtMost1200pxWide(css`
    align-items: unset;
    flex-direction: column;
    gap: 28px;
  `)}

  ${forScreenAtMost680pxWide(css`
    gap: 4px;
  `)}
`;

const FooterMenuLink = styled(RegularLink).attrs({
  $color: 'primaryAccented',
  $hover: 'linkHover',
  $focus: 'primaryAccented',
  $focusBg: 'focus',
})``;

const FooterSecondLine = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 36px;
  border-top: 1px solid ${({ theme }) => theme.colors.accents.footer};
  padding-top: 28px;

  font-size: ${({ theme }) => theme.fontSizes.footer.secondLine};
  color: ${({ theme }) => theme.colors.texts.delicate};

  ${forScreenAtMost680pxWide(css`
    flex-direction: row;
    gap: 8px;

    position: relative;

    div {
      flex-direction: column;
    }

    ${CraftedByMitsWrapper} {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  `)}
`;

export default Footer;
