import React from 'react';
import styled, { css } from 'styled-components';
import {
  forScreenAtLeast1000pxWide,
  forScreenAtMost1000pxWide,
} from '../../styles/mediaQueries';

interface FooterMenuProps {
  links: { icon?: React.ReactNode; label: string; path: string }[];
  linkComponent: React.ComponentType<{ to: string }>;
  separatorColors: SeparatorColors;
}

interface SeparatorColors {
  primary: string;
  secondary: string;
}

const FooterMenu = ({
  links,
  linkComponent,
  separatorColors,
}: FooterMenuProps): JSX.Element => {
  const MenuLink = linkComponent;

  return (
    <Ul>
      {links.map(({ icon, label, path }) => (
        <Li key={label} separatorColors={separatorColors}>
          <MenuLink to={path}>{icon ? icon : label}</MenuLink>
        </Li>
      ))}
    </Ul>
  );
};

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  ${forScreenAtMost1000pxWide(css`
    flex-direction: column;
  `)}
`;

const Li = styled.li<{ separatorColors: SeparatorColors }>`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ separatorColors }) => css`
    ${forScreenAtLeast1000pxWide(css`
      :not(:last-child) {
        :after {
          content: '';
          display: inline-block;
          margin: 3px 20px;
          width: 9px;
          height: 3px;
          border-bottom: 1px solid ${separatorColors.primary};
          border-top: 1px dashed ${separatorColors.secondary};
        }
      }
    `)}
  `}
`;

export default FooterMenu;
