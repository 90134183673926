import { Link } from 'gatsby';
import styled from 'styled-components';
import { LinkColorProps, LinkColorStyles } from './Link';

const IconLink = styled(Link).attrs({
  $focusBg: 'focusSecondary',
})<LinkColorProps>`
  ${LinkColorStyles}

  margin: 0 0 0 auto;
`;

export default IconLink;
