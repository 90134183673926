import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import HamburgerMenu from '../../components/atoms/HamburgerMenu';
import NavButton from '../../components/atoms/NavButton';
import NavLogoWrapper from '../../components/atoms/NavLogoWrapper';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import useIsScrolled from '../../hooks/useScroll';
import routes from '../../routes';
import { forScreenAtMost1200pxWide } from '../../styles/mediaQueries';
import { NavMenuLink } from '../atoms/NavMenuItem';
import { MainContentColumnWrapper } from './Layout';
import DesktopMenu, { InnerSubMenu } from './navigation/DesktopMenu';
import MobileMenu from './navigation/MobileMenu';
import UEFlag from './navigation/UEFlag';

export type ChildrenMenuType = {
  label: string;
  path: string;
  mobileBreadcrumb?: string;
};

export interface MenuLinksProps {
  tabs: {
    label: string | React.ReactNode;
    path: string;
    isHiddenOnMobile?: boolean;
    children?: ChildrenMenuType[];
    component?: string;
    icon?: React.ReactNode;
  }[];
}

interface TopNavigationProps {
  initialWhiteBackground: boolean;
}

export const tabs = [
  {
    label: 'Platform',
    path: routes.home,
  },
  {
    label: 'Products',
    path: '',
    children: [
      {
        label: 'Antibody Database',
        path: routes.antibodyKnowledgeGraph,
        mobileBreadcrumb: 'Products',
      },
      {
        label: 'Antibody Analytics',
        path: routes.antibodyAnalytics,
        mobileBreadcrumb: 'Products',
      },
    ],
  },
  {
    label: 'Company',
    path: '',
    children: [
      {
        label: 'About us',
        path: routes.aboutUs,
        mobileBreadcrumb: 'Company',
      },
      {
        label: 'Newsroom',
        path: routes.newsroom,
        mobileBreadcrumb: 'Company',
      },
      {
        label: 'Careers',
        path: routes.careers,
        mobileBreadcrumb: 'Company',
      },
    ],
  },
  {
    label: 'Resources',
    path: '',
    children: [
      {
        label: 'NaturalAntibody Publications',
        path: routes.resourcesYear,
        mobileBreadcrumb: 'Resources',
      },
      {
        label: 'AbNGS Database',
        path: routes.ngs,
        mobileBreadcrumb: 'Resources',
      },
      {
        label: 'PairedNGS Database',
        path: routes.pairedNGS,
        mobileBreadcrumb: 'Resources',
      },
    ],
  },
  {
    label: 'Grants',
    path: routes.grants,
    icon: <UEFlag />,
  },
  {
    label: 'Contact Us',
    path: routes.contactUs,
    component: 'NavButton',
    isHiddenOnMobile: true,
  },
];

const TopNavigation = ({
  initialWhiteBackground,
}: TopNavigationProps): JSX.Element => {
  const isScrolled = useIsScrolled();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const narrowLayout = useMaxWidthBreakpoint(1200);

  const handleMenuActive = () => {
    document.body.classList.toggle('menu-open');
    setIsMenuOpen(!isMenuOpen);
  };

  const whiteBackground = initialWhiteBackground || isScrolled;

  return (
    <NavWrapper isScrolled={isScrolled} whiteBackground={whiteBackground}>
      <MainContentColumnWrapper>
        <Nav>
          <NavLogoWrapper
            isScrolled={isScrolled || initialWhiteBackground}
            open={isMenuOpen}
          />
          {narrowLayout ? (
            <>
              <MobileMenu
                handleMenuOpen={handleMenuActive}
                open={isMenuOpen}
                tabs={tabs}
              />
              <HamburgerMenu
                handleMenuOpen={handleMenuActive}
                open={isMenuOpen}
                scrolled={isScrolled || initialWhiteBackground}
              />
            </>
          ) : (
            <DesktopMenu tabs={tabs} whiteBackground={whiteBackground} />
          )}
        </Nav>
      </MainContentColumnWrapper>
    </NavWrapper>
  );
};

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0;
  transition: all 0.2s ease-in-out;

  ${forScreenAtMost1200pxWide(css`
    justify-content: space-between;
  `)}
`;

const NavWrapper = styled.div<{
  isScrolled: boolean;
  whiteBackground: boolean;
}>`
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.navigation};
  width: 100%;
  transition: background 0.2s ease-in-out;

  ${({ whiteBackground }) =>
    whiteBackground &&
    css`
      background: transparent;

      ${NavMenuLink} {
        color: ${({ theme }) => theme.colors.texts.secondary};

        :hover {
          color: ${({ theme }) => theme.colors.texts.tertiary};
        }
      }

      ${NavButton} {
        background: ${({ theme }) => theme.colors.backgrounds.primaryAccented};
      }

      ${InnerSubMenu} {
        ${NavMenuLink} {
          color: ${({ theme }) => theme.colors.texts.primaryAccented};
        }
      }
    `}

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      box-shadow: ${({ theme }) => theme.boxShadows.navShadow};
      background: ${({ theme }) => theme.colors.backgrounds.secondary};

      ${Nav} {
        margin: 14px 0 16px;
      }
    `}
`;

export default TopNavigation;
