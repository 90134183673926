import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { LinkColorProps, LinkColorStyles } from './Link';
import { forScreenAtMost1200pxWide } from '../../styles/mediaQueries';

export const NavMenuItem = styled.li`
  margin-right: 45px;
  position: relative;

  ${forScreenAtMost1200pxWide(css`
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #103f46;
    padding: 15px 15px 17px;
  `)}
`;

export const NavMenuLink = styled(Link).attrs({ $focusBg: 'focusSecondary' })<
  LinkColorProps & {
    to: string;
    as?: string;
    partiallyActive?: boolean;
    activeClassName?: string;
  }
>`
  ${LinkColorStyles}

  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  font-weight: 700;
  position: relative;
  padding: 0 5px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.texts.primaryAccented};
  transition: all 0.2s ease-in-out;

  ${({ to }) =>
    to === '' &&
    css`
      cursor: default;
    `};

  > svg {
    margin-left: 8px;
  }

  :after {
    position: absolute;
    bottom: -3px;
    height: 1px;
    left: 0;
    width: 0;
    content: '';
    opacity: 0;
    background: ${({ theme }) => theme.colors.accents.primary};
    box-shadow: ${({ theme }) => theme.boxShadows.navMenuGlow};
    transition: all 0.3s ease-in-out;
  }

  :hover {
    color: ${({ theme }) => theme.colors.accents.secondary};

    :after {
      opacity: 1;
      width: 100%;
    }
  }
`;
