import React from 'react';
import styled, { css } from 'styled-components';
import { forScreenAtMost800pxWide } from '../../../styles/mediaQueries';
import { GradientHeader, TextSmall } from '../../atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../Layout';
import { calendlyLink } from '../../../constants';
import ceo from '../../../assets/images/share/ceo.png';

const BookDemo = (): JSX.Element => (
  <Wrapper>
    <MainContentColumnWrapper>
      <ContactInvitation>
        <Content>
          <ImageBox>
            <img alt="Photo CEO" src={ceo} />
          </ImageBox>
          <TextBox>
            <SectionTitle>Book a 30-minute demo</SectionTitle>
            <ExternalButton href={calendlyLink}>Let&apos;s talk</ExternalButton>
            <TextSmall $color="delicate">
              Learn more on NaturalAntibody.com
            </TextSmall>
          </TextBox>
        </Content>
      </ContactInvitation>
    </MainContentColumnWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -50%;
  width: 100%;
  top: -167px;

  ${forScreenAtMost800pxWide(css`
    top: -307px;
  `)}
`;

const ContactInvitation = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgrounds.primaryAccented};
  width: 100%;
  height: 270px;

  ${forScreenAtMost800pxWide(css`
    height: 410px;
  `)}

  :before {
    content: '';
    position: absolute;
    width: 63%;
    background-color: ${({ theme }) =>
      theme.colors.backgrounds.primaryAccented};
    height: 10px;
    top: -10px;
  }
`;

const ImageBox = styled.div`
  display: flex;
  width: 30%;
  height: 11rem;
  position: relative;
  margin-right: 8rem;

  img {
    position: absolute;
    width: 100%;
    max-width: 300px;
    height: min-content;
    bottom: -5rem;
    left: 5rem;
    z-index: 2;

    ${forScreenAtMost800pxWide(css`
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      margin-bottom: 4rem;
    `)}
  }

  ${forScreenAtMost800pxWide(css`
    width: 100%;
  `)}
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  ${forScreenAtMost800pxWide(css`
    align-items: center;

    h2 {
      text-align: center;
    }
  `)}
`;

const SectionTitle = styled(GradientHeader).attrs({
  $gradient: 'primary',
  $color: 'delicate',
})`
  font-size: 42px;
  margin-bottom: 2rem;

  p {
    white-space: nowrap;

    ${forScreenAtMost800pxWide(css`
      font-size: 38px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;

  ${forScreenAtMost800pxWide(css`
    flex-direction: column;
    justify-content: center;
  `)}
`;

const ExternalButton = styled.a`
  margin: 1.5rem 0;
  font-size: 14px;
  padding: 16px 2px;
  color: ${({ theme }) => theme.colors.texts.primaryAccented};
  background: ${({ theme }) => theme.colors.accents.primary};
  white-space: nowrap;
  max-width: 8rem;
  border-radius: 51px;
  text-decoration: none;
  transition: 0.2s background;
  text-align: center;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) =>
      theme.colors.accents.secondaryAccentedTransparent};
  }
`;

export default BookDemo;
