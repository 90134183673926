import { useEffect, useState } from 'react';
import { Breakpoint, maxWidthQuery } from '../styles/mediaQueries';

const useMaxWidthBreakpoint = (breakpoint: Breakpoint): boolean => {
  if (typeof window !== 'undefined') {
    const mql = window.matchMedia(`(${maxWidthQuery(breakpoint)})`);

    const [isMaxWidth, setIsMaxWidth] = useState(mql.matches);

    useEffect(() => {
      const handler = (e: { matches: boolean }) => setIsMaxWidth(e.matches);
      mql.addEventListener('change', handler);

      return () => mql.removeEventListener('change', handler);
    }, []);

    return isMaxWidth;
  } else {
    // SSR
    return false;
  }
};

export default useMaxWidthBreakpoint;
