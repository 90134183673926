import { Link } from 'gatsby';
import styled, { css, DefaultTheme } from 'styled-components';
import accentSvg from '../../assets/images/accent.svg';
import accentArrowSvg from '../../assets/images/accentArrow.svg';
import { LinkProps, SharedLinkStyles } from './Link';

interface AccentedLinkProps extends LinkProps {
  $accentColor: keyof DefaultTheme['colors']['accents'];
  $withArrow?: boolean;
  $smaller?: boolean;
  $widerSpacing?: boolean;
}

const SharedAccentedLinkStyles = css<AccentedLinkProps>`
  position: relative;
  display: inline-block;
  font-weight: bold;

  ${SharedLinkStyles}

  ${({ $smaller }) =>
    $smaller
      ? css`
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.2em;
        `
      : css`
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.5em;
        `}

  ${({ $widerSpacing }) =>
    $widerSpacing
      ? css`
          letter-spacing: 0.5em;
        `
      : css`
          letter-spacing: 0.2em;
        `}

  &:after {
    content: '';
    display: inline-block;
    margin-left: ${({ $widerSpacing }) => ($widerSpacing ? '12px' : '10px')};
    background: ${({ theme, $accentColor }) =>
      theme.colors.accents[$accentColor]};

    ${({ $withArrow }) =>
      $withArrow
        ? css`
            width: 28px;
            height: 8px;
            mask-image: url('${accentArrowSvg}');
          `
        : css`
            width: 27px;
            height: 7px;
            margin-bottom: 1px;
            mask-image: url('${accentSvg}');
          `}
  }
`;

const AccentedLink = styled(Link).attrs({
  $uppercase: true,
})<AccentedLinkProps>`
  ${SharedAccentedLinkStyles}
`;

export const AccentedLinkSmall = styled(AccentedLink).attrs((currentProps) => ({
  $accentColor: 'primary',
  $color: 'delicate',
  $hover: 'primaryAccented',
  $focus: 'primaryAccented',
  $focusBg: 'focus',
  $smaller: true,
  ...currentProps,
}))``;

export const AccentedLinkBig = styled(AccentedLink).attrs((currentProps) => ({
  $accentColor: 'secondary',
  $color: 'primary',
  $hover: 'linkHover',
  $focus: 'primary',
  $focusBg: 'focus',
  $withArrow: true,
  ...currentProps,
}))``;

export const AccentedLinkExternal = styled('a').attrs({
  $uppercase: true,
  rel: 'noreferrer',
  target: '_blank',
})<AccentedLinkProps>`
  ${SharedAccentedLinkStyles}
`;
