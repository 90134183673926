import { Link } from 'gatsby';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

interface CommonLinkProps {
  $uppercase?: boolean;
  $textAlign?: 'right' | 'center' | 'left';
  $breakWord?: boolean;
}

export interface LinkColorProps {
  $color?: keyof DefaultTheme['colors']['texts'];
  $hover?: keyof DefaultTheme['colors']['texts'];
  $focus?: keyof DefaultTheme['colors']['texts'];
  $focusBg?: keyof DefaultTheme['colors']['backgrounds'];
}

type LinkWithColorProps = CommonLinkProps & LinkColorProps;

export type LinkProps = LinkWithColorProps;

const colorTextStyles = (
  color: keyof DefaultTheme['colors']['texts'] | undefined,
): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
  color
    ? css`
        color: ${({ theme }) => theme.colors.texts[color]};
      `
    : css``;

export const LinkColorStyles = css<LinkColorProps>`
  ${({ $color, $hover, $focus, $focusBg }) => css`
    ${colorTextStyles($color)}

    transition: 0.2s color ease-in-out;

    ${$hover &&
    css`
      &:hover {
        color: ${({ theme }) => theme.colors.texts[$hover]};
      }
    `}

    ${$focus &&
    css`
      &:focus-visible {
        color: ${({ theme }) => theme.colors.texts[$focus]};

        * {
          color: ${({ theme }) => theme.colors.texts[$focus]};
        }
      }
    `}

    ${$focusBg &&
    css`
      position: relative;
      z-index: ${({ theme }) => theme.layers.base};

      &:focus-visible {
        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          padding: 5px;
          top: -5px;
          left: -5px;
          width: 100%;
          height: 100%;
          background-color: ${({ theme }) =>
            theme.colors.backgrounds[$focusBg]};
        }
      }
    `}
  `};
`;

export const SharedLinkStyles = css<LinkProps>`
  cursor: pointer;
  text-decoration: none;
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
  word-break: ${({ $breakWord }) => ($breakWord ? 'break-word' : 'normal')};

  ${LinkColorStyles}
`;

export const UpperCaseLinkMedium = styled(Link)<LinkProps>`
  ${SharedLinkStyles};
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2em;
  font-weight: 700;
  text-transform: uppercase;
`;

export const UpperCaseLinkSmall = styled(Link)<LinkProps>`
  ${SharedLinkStyles};
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2em;
  font-weight: 700;
  text-transform: uppercase;
`;

export const LinkMobileNav = styled(Link)<LinkProps>`
  ${SharedLinkStyles};
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.03em;
  font-weight: 700;
`;

export const RegularLink = styled(Link)<LinkProps>`
  ${SharedLinkStyles};
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
`;

export const ExternalLink = styled.a.attrs({
  rel: 'noreferrer',
})<LinkProps>`
  ${SharedLinkStyles};
  text-decoration: none;
`;
