import { useCallback } from 'react';
import { useEffect, useState } from 'react';

const useIsScrolled = (): boolean => {
  const [isScrolled, setIsScrolled] = useState(false);

  const scrollCallback = useCallback(() => {
    setIsScrolled(window.scrollY > 0);
  }, [setIsScrolled]);

  useEffect(() => {
    scrollCallback();

    window.addEventListener('scroll', scrollCallback);

    return () => {
      window.removeEventListener('scroll', scrollCallback);
    };
  }, [scrollCallback]);

  return isScrolled;
};

export default useIsScrolled;
