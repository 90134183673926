import * as React from 'react';
import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  theme?: string;
}

const UEFlag: React.FC<CustomSVGProps> = (props) => {
  return (
    <svg
      fill="none"
      height={33}
      width={51}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M.5.677h49.315v31.646H.5V.677Z" stroke={props.theme || '#FFF'} />
      <path
        d="m24.085 7.293 1.06-.753 1.066.753-.41-1.218 1.08-.754h-1.326l-.41-1.238-.404 1.238h-1.332l1.076.754-.4 1.218ZM18.587 8.728l1.065-.753 1.06.753-.404-1.219 1.08-.753H20.06l-.41-1.238-.409 1.238h-1.326l1.08.753-.41 1.22ZM15.628 9.438l-.405 1.239h-1.326l1.075.753-.405 1.219 1.06-.749 1.066.749-.405-1.219 1.08-.753h-1.331l-.41-1.239ZM14.158 17.232l1.06.758-.404-1.219 1.08-.753h-1.332l-.404-1.239-.41 1.239h-1.33l1.08.753-.405 1.219 1.065-.758ZM16.037 21.373l-.41-1.233-.404 1.233h-1.326l1.075.758-.405 1.215 1.06-.754 1.066.754-.405-1.215 1.08-.758h-1.331ZM20.066 25.294l-.41-1.233-.409 1.233h-1.326l1.08.753-.405 1.22 1.06-.754 1.066.753-.405-1.219 1.08-.753h-1.331ZM25.555 26.714l-.41-1.238-.404 1.238h-1.332l1.08.754-.404 1.218 1.06-.753 1.066.753-.41-1.218 1.085-.754h-1.331ZM31.043 25.294l-.41-1.233-.408 1.233h-1.327l1.075.753-.4 1.22 1.06-.754 1.061.753-.404-1.219 1.085-.753h-1.332ZM35.072 21.373l-.409-1.233-.404 1.233h-1.332l1.08.758-.41 1.215 1.066-.754 1.06.754-.404-1.215 1.08-.758h-1.327ZM37.859 16.003h-1.332l-.404-1.238-.41 1.238h-1.326l1.08.754-.41 1.218 1.066-.753 1.06.753-.404-1.218 1.08-.754ZM33.598 12.63l1.065-.749 1.065.749-.41-1.22 1.08-.748h-1.326l-.409-1.238-.404 1.243-1.332-.005 1.08.749-.41 1.219ZM30.649 5.518l-.405 1.238h-1.331l1.08.753-.404 1.22 1.065-.759 1.06.758-.404-1.219 1.075-.753h-1.327l-.41-1.238Z"
        fill={props.theme || '#FFF'}
      />
    </svg>
  );
};

export default UEFlag;
