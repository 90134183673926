import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { forScreenAtMost600pxHeight } from '../../../styles/mediaQueries';
import { LinkMobileNav } from '../../atoms/Link';
import { NavMenuItem } from '../../atoms/NavMenuItem';
import { ChildrenMenuType, MenuLinksProps } from '../TopNavigation';
import { TextLarge } from '../../atoms/Typography/Typography';
import { ChildrenMobileMenu } from './ChildrenMobileMenu';
import ArrowLeftIcon from '../../../assets/images/arrowLeft.svg';
import ArrowRightIcon from '../../../assets/images/arrowRight.svg';
import routes from '../../../routes';
import NavButton from '../../atoms/NavButton';

interface MobileMenuProps extends MenuLinksProps {
  open: boolean;
  handleMenuOpen: () => void;
}

const MobileMenu = ({
  open,
  handleMenuOpen,
  tabs,
}: MobileMenuProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<ChildrenMenuType[] | []>([]);

  const onSetMobileTab = (value: ChildrenMenuType[]) => setSelectedTab(value);

  return (
    <MobileOverlay open={open}>
      {!!selectedTab.length && (
        <ButtonMobileNav
          onClick={() => {
            onSetMobileTab([]);
          }}
        >
          <img src={ArrowLeftIcon} />
          <BreadcrumbBackText>
            {selectedTab[0].mobileBreadcrumb}
          </BreadcrumbBackText>
        </ButtonMobileNav>
      )}
      <StyledMobileMenu>
        {!selectedTab.length &&
          tabs.map((tab, index) => (
            <React.Fragment key={index}>
              {tab.children ? (
                <NavMenuItem>
                  <ButtonMobileNav
                    onClick={() => {
                      tab.children && onSetMobileTab(tab.children);
                    }}
                  >
                    <TextLarge $color="primary" $fontWeight="700">
                      {tab.label}
                    </TextLarge>
                    <img src={ArrowRightIcon} width={32} height={32} />
                  </ButtonMobileNav>
                </NavMenuItem>
              ) : (
                !tab.isHiddenOnMobile && (
                  <NavMenuItem onClick={handleMenuOpen}>
                    <LinkMobileNav partiallyActive={true} to={tab.path || '#'}>
                      {tab.icon ? tab.icon : tab.label}
                    </LinkMobileNav>
                  </NavMenuItem>
                )
              )}
            </React.Fragment>
          ))}
        {!!selectedTab && (
          <ChildrenMobileMenu
            selectedTab={selectedTab}
            handleMenuOpen={handleMenuOpen}
          />
        )}
      </StyledMobileMenu>
      <ContactUsButton to={routes.contactUs}>Contact us</ContactUsButton>
    </MobileOverlay>
  );
};

const BreadcrumbBackText = styled.span`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2em;
  font-weight: 500;
  text-transform: uppercase;
  color: #667c7a;
  margin-right: auto;
`;

const ContactUsButton = styled(NavButton)`
  margin: 0 auto;
  background-color: #73e2a7;
  color: #000;
`;

const ButtonMobileNav = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: #73e2a7;
  }

  p {
    font-size: 22px;
  }
`;

const StyledMobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  list-style: none;
  margin: 0;
  padding: 0;
  height: calc(100% - 80px);

  ${NavMenuItem}:nth-last-child(1) {
    border-bottom: none;
  }

  ${LinkMobileNav} {
    color: ${({ theme }) => theme.colors.texts.primaryAccented};

    &:hover,
    &.active {
      color: ${({ theme }) => theme.colors.texts.activeMenuLink};
    }
  }
`;

const MobileOverlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: -100%;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #001216e5;
  backdrop-filter: blur(15px);
  transition: all 0.5s ease-out;
  z-index: 10;
  margin: 0;
  padding: 100px 33px 50px 33px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${forScreenAtMost600pxHeight(
    css`
      flex-wrap: wrap;
    `,
  )}

  ${({ open }) =>
    open &&
    css`
      top: 0;
      opacity: 1;
    `}
`;

export default MobileMenu;
