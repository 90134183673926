import React from 'react';
import styled from 'styled-components';
import SocialLink, {
  SocialLinkType,
  SocialLinkWrapper,
} from '../atoms/SocialLink';

interface SocialLinksProps {
  twitter: string;
  linkedin: string;
  style: 'light' | 'dark';
}

const SocialLinks = ({
  // facebook,
  twitter,
  linkedin,
  style,
}: SocialLinksProps): JSX.Element => (
  <Wrapper>
    <SocialLink href={twitter} style={style} type={SocialLinkType.Twitter} />
    <SocialLink href={linkedin} style={style} type={SocialLinkType.LinkedIn} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  ${SocialLinkWrapper}:not(:last-child) {
    margin-right: 10px;
  }
`;

export default SocialLinks;
