import React from 'react';
import styled, { css } from 'styled-components';
import { socials } from '../../../constants';
import { gradientTextStyles } from '../../atoms/Typography/Typography';
import SocialLinks from '../../molecules/SocialLinks';

interface SocialSectionProps {
  verticalLayout: boolean;
}

const SocialSection = ({ verticalLayout }: SocialSectionProps): JSX.Element => (
  <SocialSectionWrapper verticalLayout={verticalLayout}>
    <SocialSectionText>Get in touch</SocialSectionText>
    <SocialLinks
      linkedin={socials.linkedin}
      style="light"
      twitter={socials.twitter}
    />
  </SocialSectionWrapper>
);

const SocialSectionWrapper = styled.div<{ verticalLayout: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  ${({ verticalLayout }) =>
    verticalLayout
      ? css`
          flex-direction: column;

          ${SocialSectionText} {
            margin-bottom: 8px;
          }
        `
      : css`
          flex-direction: row;

          ${SocialSectionText} {
            margin-right: 24px;
          }
        `}
`;

const SocialSectionText = styled.div`
  align-self: center;

  ${gradientTextStyles('primary')};
  font-weight: bold;
`;

export default SocialSection;
