import React from 'react';
import styled, { css } from 'styled-components';
import routes from '../../../routes';
import {
  forScreenAtMost1000pxWide,
  forScreenAtMost800pxWide,
} from '../../../styles/mediaQueries';
import { AccentedLinkBig } from '../../atoms/AccentedLink';
import { GradientTitle, TextMedium } from '../../atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../Layout';

const ContactInvitationBox = (): JSX.Element => (
  <ContactInvitationWrapper>
    <MainContentColumnWrapper>
      <ContactInvitation>
        <ContactInvitationInnerWrapper>
          <FooterGradientHeader>How to work with us</FooterGradientHeader>
          <ContactInvitationContent>
            <Title>
              You can work with us by individual licensing, GUI access, or
              custom services
            </Title>
            <Paragraph>
              Would you like to learn more about our database and analytics
              solutions or services? Reach out us, we look forward to hearing
              from you.
            </Paragraph>
            <AccentedLinkBig
              $focusBg="focusSecondary"
              $widerSpacing
              to={routes.contactUs}
            >
              Contact us
            </AccentedLinkBig>
          </ContactInvitationContent>
        </ContactInvitationInnerWrapper>
      </ContactInvitation>
    </MainContentColumnWrapper>
  </ContactInvitationWrapper>
);

const ContactInvitationWrapper = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -50%;
  width: 100%;
  top: -167px;

  ${forScreenAtMost800pxWide(css`
    top: -307px;
  `)}
`;

const ContactInvitation = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgrounds.primaryAccented};
  width: 100%;
  height: 280px;

  ${forScreenAtMost800pxWide(css`
    height: 430px;
  `)}

  :before {
    content: '';
    position: absolute;
    width: 63%;
    background-color: ${({ theme }) =>
      theme.colors.backgrounds.primaryAccented};
    height: 10px;
    top: -10px;
  }
`;

const ContactInvitationContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const FooterGradientHeader = styled(GradientTitle).attrs({
  as: 'h2',
  $gradient: 'secondary',
})`
  margin: 0;
  width: 270px;
`;

const Title = styled(TextMedium).attrs({ $color: 'box' })`
  margin-bottom: 10px;
  max-width: 326px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.03em;
`;

const Paragraph = styled(TextMedium).attrs({ $color: 'delicate' })`
  margin: 0;
  margin-bottom: 20px;
  max-width: 326px;
  line-height: 20px;
`;

const ContactInvitationInnerWrapper = styled.div`
  margin: 0 auto;
  padding: 50px 5% 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 200px;

  ${forScreenAtMost1000pxWide(css`
    gap: 30px;
  `)}

  ${forScreenAtMost800pxWide(css`
    flex-direction: column;
    gap: 20px;
    align-items: left;
    padding-left: 30px;
    padding-right: 30px;

    ${FooterGradientHeader}, ${Paragraph} {
      width: auto;
      max-width: 250px;
    }
  `)}
`;

export default ContactInvitationBox;
