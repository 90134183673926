import { useLocation } from '@reach/router';
import React from 'react';
import styled, { css } from 'styled-components';
import MenuArrow from '../../../assets/images/MenuArrow.inline.svg';
import NavButton from '../../atoms/NavButton';
import { NavMenuItem, NavMenuLink } from '../../atoms/NavMenuItem';
import { MenuLinksProps } from '../TopNavigation';
import IconLink from '../../atoms/IconLink';
import UEFlag from './UEFlag';

interface DesktopMenuProps extends MenuLinksProps {
  whiteBackground?: boolean;
}

const DesktopMenu = ({
  tabs,
  whiteBackground,
}: DesktopMenuProps): JSX.Element => {
  const location = useLocation();

  return (
    <DesktopMenuWrapper whiteBackground={whiteBackground}>
      {tabs.map((tab, index) => (
        <NavMenuItem key={index}>
          {tab.label === 'Grants' ? (
            <IconLink to={tab.path}>
              <UEFlag theme={whiteBackground ? '#0A2631' : '#FFFFFF'} />
            </IconLink>
          ) : tab.component === 'NavButton' ? (
            <NavButton to={tab.path}>{tab.label}</NavButton>
          ) : (
            <NavMenuLink
              activeClassName={tab.path === location.pathname ? 'active' : ''}
              as={tab.path === '' ? 'span' : undefined}
              className={
                tab.children?.some((child) => location.pathname === child.path)
                  ? 'active'
                  : undefined
              }
              partiallyActive={true}
              to={tab.path}
            >
              {tab.label}
              {tab.children && <MenuArrow />}
            </NavMenuLink>
          )}

          {tab.children && (
            <>
              <SubMenu>
                <InnerSubMenu>
                  {tab.children.map((subtab) => (
                    <NavMenuItem key={subtab.label}>
                      <NavMenuLink
                        activeClassName="active"
                        partiallyActive={true}
                        to={subtab.path}
                      >
                        {subtab.label}
                      </NavMenuLink>
                    </NavMenuItem>
                  ))}
                </InnerSubMenu>
              </SubMenu>
            </>
          )}
        </NavMenuItem>
      ))}
    </DesktopMenuWrapper>
  );
};

export default DesktopMenu;

const DesktopMenuWrapper = styled.ul<{ whiteBackground?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0 0 0 60px;
  padding: 0;
  width: 100%;

  ${NavMenuItem}:nth-last-child(2) {
    margin-left: auto;
    margin-right: 25px;

    a {
      display: inline-block;
    }
  }

  ${NavMenuLink} {
    color: ${({ theme }) => theme.colors.texts.primaryAccented};

    &.active {
      color: ${({ theme, whiteBackground }) =>
        whiteBackground
          ? theme.colors.texts.activeMenuLinkSecondary
          : theme.colors.texts.activeMenuLink};
    }
  }

  ${({ whiteBackground }) =>
    whiteBackground &&
    css`
      ${NavMenuLink} {
        &:after {
          box-shadow: none;
        }
      }
    `}
`;

export const InnerSubMenu = styled.ul`
  list-style: none;
  padding: 8px 32px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.backgrounds.primaryTransparent};
  backdrop-filter: blur(15px);

  ${NavMenuItem} {
    margin: 20px 0;
    min-width: 190px;
  }
`;

const SubMenu = styled.div`
  position: absolute;
  top: 100%;
  left: -32px;
  opacity: 0;
  pointer-events: none;
  border-top: 17px solid transparent;
  transition: opacity 0.2s ease-in-out;

  ${NavMenuItem}:hover &, &:focus-within {
    pointer-events: auto;
    opacity: 1;
  }
`;
