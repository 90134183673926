export default {
  home: '/',
  antibodyKnowledgeGraph: '/antibody-database/',
  antibodyAnalytics: '/antibody-analytics/',

  aboutUs: '/about-us/',
  resources: '/resources/',
  resourcesYear: '/resources/year/',
  researchSpace: '/resources/research-space/',
  newsroom: '/newsroom/',
  useCase: '/use-case/:slug/',
  contactUs: '/contact-us/',

  privacyPolicy: '/privacy-policy/',
  cookiesPolicy: '/cookies-policy/',

  careers: '/careers/',

  grants: '/grants/',
  announcements: '/announcements/',
  antibodyDiscoveryPlatform: '/antibody-discovery-platform',
  pairedNGS: '/paired-ngs',
  ngs: '/ngs',
};
