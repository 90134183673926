import React from 'react';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import facebookSvg from '../../assets/images/social/fb.svg';
import linkedinSvg from '../../assets/images/social/linkedin.svg';
import twitterSvg from '../../assets/images/social/twitter.svg';
import youtubeSvg from '../../assets/images/social/youtube.svg';
import { ExternalLink } from './Link';

interface SocialLinkProps {
  href: string;
  style: 'light' | 'dark';
  type: SocialLinkType;
}

export enum SocialLinkType {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  LinkedIn = 'LinkedIn',
  YouTube = 'YouTube',
}

const SocialLink = ({ href, style, type }: SocialLinkProps): JSX.Element => (
  <SocialLinkWrapper $style={style}>
    <SocialLinkIcon $style={style} $type={type} href={href}>
      {type}
    </SocialLinkIcon>
  </SocialLinkWrapper>
);

const iconStyles = (
  $type: SocialLinkProps['type'],
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  switch ($type) {
    case SocialLinkType.Facebook:
      return css`
        mask-image: url('${facebookSvg}');
      `;
    case SocialLinkType.Twitter:
      return css`
        mask-image: url('${twitterSvg}');
      `;
    case SocialLinkType.LinkedIn:
      return css`
        mask-image: url('${linkedinSvg}');
      `;
    case SocialLinkType.YouTube:
      return css`
        mask-image: url('${youtubeSvg}');
      `;
    default:
      return css``;
  }
};

export const SocialLinkWrapper = styled.span<{
  $style: SocialLinkProps['style'];
}>`
  width: 50px;
  height: 50px;

  position: relative;
  z-index: ${({ theme }) => theme.layers.base};

  ${({ $style }) =>
    $style === 'dark'
      ? css`
          border: 1px solid
            ${({ theme }) => theme.colors.borders.primaryTransparent};

          :hover {
            background: ${({ theme }) =>
              theme.colors.borders.primaryTransparent};
          }

          :focus-within {
            background: ${({ theme }) => theme.colors.backgrounds.focus};
          }
        `
      : css`
          border: 1px solid
            ${({ theme }) => theme.colors.borders.secondaryTransparent};

          :hover {
            background: ${({ theme }) =>
              theme.colors.borders.secondaryTransparent};
          }

          :focus-within {
            background: ${({ theme }) =>
              theme.colors.backgrounds.focusSecondary};
          }
        `}

  border-radius: 50%;
  transition: background 0.2s;
`;

const SocialLinkIcon = styled(ExternalLink)<{
  $style: SocialLinkProps['style'];
  $type: SocialLinkProps['type'];
}>`
  display: block;
  height: 100%;

  ${({ $style }) =>
    $style === 'dark'
      ? css`
          background: ${({ theme }) => theme.colors.texts.delicateAccented};

          :focus {
            background: ${({ theme }) => theme.colors.backgrounds.secondary};
          }
        `
      : css`
          background: ${({ theme }) => theme.colors.backgrounds.secondary};
        `}

  ${({ $type }) => iconStyles($type)};
  mask-position: center;
  mask-repeat: no-repeat;
`;

export default SocialLink;
