import { DefaultTheme } from 'styled-components';
import colors from './colors';

export const greenTheme: DefaultTheme = {
  colors: {
    texts: {
      primary: colors.white,
      primaryAccented: colors.almostWhite,
      secondary: colors.darkGraphite,
      delicate: colors.grey,
      delicateAccented: colors.midSeaGrey,
      delicateStronger: colors.darkerGrey,
      tertiary: colors.seaBlue,
      tertiaryStronger: colors.darkSeaBlue,
      error: colors.lightPink,
      okMessage: colors.richGreen,
      errorMessage: colors.darkPink,
      activeMenuLink: colors.blinkGreen,
      activeMenuLinkSecondary: colors.richGreen,
      linkHover: colors.mainGreen,
      scrollLinkHover: colors.almostWhite,
    },
    backgrounds: {
      primary: colors.veryDarkSeaBlue,
      footer: colors.darkSeaBlue,
      primaryAccented: colors.seaBlue,
      primaryTransparent: colors.darkSeaBlueTransparent,
      secondary: colors.white,
      secondaryAccented: colors.almostWhite,
      secondaryPair: colors.lightBlueGrey,
      button: colors.almostWhiteTransparent,
      buttonSecondary: colors.seaBlue,
      okMessage: colors.veryLightYellowGreen,
      errorMessage: colors.veryLightYellowGreen,
      focus: colors.seaBlue,
      focusSecondary: colors.mainGreenTransparent,
      tertiary: colors.lightBlinkGreen,
      quaternary: colors.veryLightGrey,
    },
    accents: {
      primary: colors.blinkGreen,
      secondary: colors.mainGreen,
      secondaryStronger: colors.richGreen,
      secondaryAccented: colors.seaBlue,
      secondaryAccentedTransparent: colors.blinkGreenTransparent,
      secondaryPair: colors.grey,
      footer: colors.lightBlueGreyTransparent,
    },
    borders: {
      primaryTransparent: colors.midSeaGreyTransparent,
      delicate: colors.lightGrey,
      secondaryTransparent: colors.lightBlueGreyTransparent,
      focus: colors.dirtyLightBlue,
      error: colors.lightPink,
    },
  },
  gradients: {
    primary: `linear-gradient(261.06deg, ${colors.grey} 43.2%, ${colors.midSeaGreenGrey} 83.48%)`,
    secondary: `linear-gradient(to right, ${colors.midSeaGreenGrey}, ${colors.grey} 60%)`,
    linesPattern: `linear-gradient(0deg, ${colors.lightGrey} 1px, transparent 1px)`,
    dotsPattern: `linear-gradient(${colors.grey} 16%, transparent 0%)`,
  },
  boxShadows: {
    navMenuGlow: `0px 0px 15px 3px ${colors.mainGreenTransparent}`,
    navShadow: `0px 4px 10px ${colors.blackTransparent}`,
  },
  fontSizes: {
    abGraph: {
      counter: '18px',
    },
    nextProductBox: {
      linkTitle: '22px',
    },
    footer: {
      firstLine: '12px',
      secondLine: '11px',
    },
    forms: {
      input: '14px',
      legalText: '12px',
      error: '14px',
    },
  },
  lineHeights: {
    nextProductBox: {
      linkTitle: '26.4px',
    },
    forms: {
      input: '24px',
      inputSmaller: '20px',
    },
  },
  layers: {
    base: 0,
    navigation: 10,
  },
};
