import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

interface MetaTagsProps {
  title?: string;
  description?: string;
  ogImage?: string;
}

const MetaTags = ({
  title,
  description,
  ogImage,
}: MetaTagsProps): JSX.Element => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: ogImage,
  };

  return (
    <Helmet titleTemplate={`%s – ${site.siteMetadata.title}`}>
      <html lang="en" />
      <title>{title}</title>
      <link href="/favicon.png" rel="icon" type="image/svg+xml" />
      <link href="/favicon.ico" rel="alternate icon" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta charSet="utf-8" />
      <meta content={seo.description} name="description" />
      {seo.description && (
        <meta content={seo.description} property="og:description" />
      )}
      {seo.title && <meta content={seo.title} name="twitter:title" />}
      {seo.url && <meta content={seo.url} property="og:url" />}
      {seo.image && (
        <meta content={seo.image} name="image" property="og:image" />
      )}
    </Helmet>
  );
};

export default MetaTags;
