export default {
  // names from Figma design:
  seaBlue: '#134F58',
  darkSeaBlue: '#0A2631',
  white: '#FFFFFF',
  grey: '#93B5B1',
  mainGreen: '#B1CF5F',
  almostWhite: '#F1F4E8',
  darkerGrey: '#5F6A69',
  richGreen: '#1C7C54',
  blinkGreen: '#73E2A7',
  lightBlinkGreen: '#F0F5F3',
  darkGraphite: '#36403F',
  darkPink: '#B52264',
  lightPink: '#DA4186',
  lightBlueGrey: '#E8F4F4',
  midSeaGrey: '#667C7A',
  // own names:
  midSeaGreenGrey: '#457771',
  almostWhiteTransparent: 'rgba(241, 244, 232, 0.3)',
  mainGreenTransparent: 'rgba(177, 207, 95, 0.6)',
  blackTransparent: 'rgba(0, 0, 0, 0.13)',
  lightenSeaBlue: 'rgba(19, 79, 88, 0.2)',
  darkSeaBlueTransparent: 'rgba(10, 38, 49, 0.8)',
  lightBlueGreyTransparent: '#E8F4F41A',
  blinkGreenTransparent: 'rgba(115, 226, 167, 0.8)',
  midSeaGreyTransparent: 'rgba(102, 124, 122, 0.1)',
  lightGrey: '#e2e2e2',
  veryLightGrey: '#ECF0F3',
  dirtyLightBlue: '#93b5b1',
  veryLightYellowGreen: '#f8f9f3',
  veryDarkSeaBlue: '#001820',
};
