import { Link } from 'gatsby';
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { LinkProps, SharedLinkStyles } from './Link';

interface LegalProps {
  authorRoute: string;
  privacyPolicyRoute: string;
  cookiesPolicyRoute: string;
  moreVisibleLinkColor: keyof DefaultTheme['colors']['texts'];
  lessVisibleLinkColor: keyof DefaultTheme['colors']['texts'];
  verticalLayout: boolean;
}

const Legal = ({
  authorRoute: homeRoute,
  privacyPolicyRoute,
  cookiesPolicyRoute,
  moreVisibleLinkColor,
  lessVisibleLinkColor,
  verticalLayout,
}: LegalProps): JSX.Element => (
  <LegalWrapper verticalLayout={verticalLayout}>
    <li>
      Copyright &copy; {new Date().getFullYear()}{' '}
      <FooterLink $color={moreVisibleLinkColor} to={homeRoute}>
        NaturalAntibody
      </FooterLink>
      . All rights reserved
    </li>
    <li>
      <FooterLink
        $color={lessVisibleLinkColor}
        $focus={moreVisibleLinkColor}
        to={privacyPolicyRoute}
      >
        Privacy policy
      </FooterLink>
    </li>
    <li>
      <FooterLink
        $color={lessVisibleLinkColor}
        $focus={moreVisibleLinkColor}
        to={cookiesPolicyRoute}
      >
        Cookies policy
      </FooterLink>
    </li>
  </LegalWrapper>
);

const LegalWrapper = styled.ul<{ verticalLayout: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;

  ${({ verticalLayout }) =>
    verticalLayout
      ? css`
          max-width: 300px;

          li {
            margin-right: 0;
          }

          li:nth-child(2) {
            margin-right: 12px;
          }
        `
      : ''}

  li:not(:last-child) {
    margin-right: 22px;
  }
`;

const FooterLink = styled(Link).attrs({ $focusBg: 'focus' })<LinkProps>`
  ${SharedLinkStyles}

  :hover {
    text-decoration: underline;
  }
`;

export default Legal;
