import { partial } from 'lodash';
import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

export const breakpoints = [1440, 1200, 1024, 1000, 800, 680] as const;
export type Breakpoint = typeof breakpoints[number];

export const maxWidthQuery = (width: Breakpoint): string =>
  `max-width: ${width}px`;
export const maxHeightQuery = (height: number): string =>
  `max-height: ${height}px`;
export const minWidthQuery = (width: Breakpoint): string =>
  `min-width: ${width}px`;

const forScreenAtMost_pxWide = (
  width: Breakpoint,
  styles: FlattenInterpolation<ThemeProps<DefaultTheme>>,
) => css`
  @media only screen and (${maxWidthQuery(width)}) {
    ${styles}
  }
`;

const forScreenAtMost_pxHeight = (
  height: number,
  styles: FlattenInterpolation<ThemeProps<DefaultTheme>>,
) => css`
  @media only screen and (${maxHeightQuery(height)}) {
    ${styles}
  }
`;

const forScreenAtLeast_pxWide = (
  width: Breakpoint,
  styles: FlattenInterpolation<ThemeProps<DefaultTheme>>,
) => css`
  @media only screen and (${minWidthQuery(width)}) {
    ${styles}
  }
`;

export const forScreenAtMost1440pxWide = partial(forScreenAtMost_pxWide, 1440);
export const forScreenAtMost1200pxWide = partial(forScreenAtMost_pxWide, 1200);
export const forScreenAtMost1024pxWide = partial(forScreenAtMost_pxWide, 1024);
export const forScreenAtMost1000pxWide = partial(forScreenAtMost_pxWide, 1000);
export const forScreenAtMost800pxWide = partial(forScreenAtMost_pxWide, 800);
export const forScreenAtMost680pxWide = partial(forScreenAtMost_pxWide, 680);
export const forScreenAtMost600pxHeight = partial(
  forScreenAtMost_pxHeight,
  600,
);
export const forScreenAtLeast680pxWide = partial(forScreenAtLeast_pxWide, 680);
export const forScreenAtLeast1000pxWide = partial(
  forScreenAtLeast_pxWide,
  1000,
);
