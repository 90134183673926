import React from 'react';
import styled, { css } from 'styled-components';
import routes from '../../../routes';
import {
  forScreenAtLeast1000pxWide,
  forScreenAtMost1000pxWide,
  forScreenAtMost800pxWide,
} from '../../../styles/mediaQueries';
import { GradientTitle, TextMedium } from '../../atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../Layout';
import NavButton from '../../atoms/NavButton';

const NgsInvitationToContact = (): JSX.Element => (
  <ContactInvitationWrapper>
    <MainContentColumnWrapper>
      <ContactInvitation>
        <ContactInvitationInnerWrapper>
          <FooterGradientHeader>
            Data availability & access
          </FooterGradientHeader>
          <ContactInvitationContent>
            <Title>
              We make the dataset available to non-profit organizations for
              non-commercial purposes.
            </Title>
            <Paragraph>
              The dataset in .fasta format is approximately ~62GB and is
              available through Amazon AWS S3 service as the requester-pays.
            </Paragraph>
            <Paragraph>
              Please send us an email via the contact form and we will get back
              with the access instructions.
            </Paragraph>
            <NavButton to={routes.contactUs}>Contact Us</NavButton>
          </ContactInvitationContent>
        </ContactInvitationInnerWrapper>
      </ContactInvitation>
    </MainContentColumnWrapper>
  </ContactInvitationWrapper>
);

const ContactInvitationWrapper = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -50%;
  width: 100%;
  top: -377px;
  ${forScreenAtLeast1000pxWide(css`
    top: -277px;
  `)}
`;

const ContactInvitation = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgrounds.primaryAccented};
  ${forScreenAtLeast1000pxWide(css`
    height: 400px;
  `)}

  :before {
    content: '';
    position: absolute;
    width: 63%;
    background-color: ${({ theme }) =>
      theme.colors.backgrounds.primaryAccented};
    height: 10px;
    top: -10px;
  }
`;

const ContactInvitationContent = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;

  ${NavButton} {
    margin: 10px 0 0 0;
    background: ${(p) => p.theme.colors.accents.secondaryAccentedTransparent};
    color: ${(p) => p.theme.colors.texts.tertiary};
  }
`;

const FooterGradientHeader = styled(GradientTitle).attrs({
  as: 'h2',
  $gradient: 'secondary',
})`
  margin: 0;
  width: 300px;
`;

const Title = styled(TextMedium).attrs({ $color: 'box' })`
  max-width: 326px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.03em;
`;

const Paragraph = styled(TextMedium).attrs({ $color: 'delicate' })`
  max-width: 326px;
  line-height: 20px;
`;

const ContactInvitationInnerWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 200px;

  ${forScreenAtMost1000pxWide(css`
    gap: 30px;
  `)}

  ${forScreenAtMost800pxWide(css`
    flex-direction: column;
    gap: 20px;
    align-items: left;
    padding-left: 30px;
    padding-right: 30px;

    ${FooterGradientHeader}, ${Paragraph} {
      width: auto;
      max-width: 250px;
    }
  `)}
`;

export default NgsInvitationToContact;
