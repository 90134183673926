// store reusable constants here

// WARNING: these should not contain ANY secrets as these are available client side

export const socials = {
  twitter: 'https://twitter.com/naturalantibody',
  linkedin: 'https://www.linkedin.com/company/naturalantibody/',
};

export const calendlyLink =
  'https://calendly.com/agnieszka-piotrowska-1/abstudio-demo-with-konrad';
