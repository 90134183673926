import React from 'react';
import styled from 'styled-components';
import MitsLogo from '../../assets/images/MitsLogo.inline.svg';

const CraftedByMits = (): JSX.Element => (
  <CraftedByMitsWrapper>
    Crafted by <MitsLogo />
  </CraftedByMitsWrapper>
);

export const CraftedByMitsWrapper = styled.div`
  svg {
    vertical-align: bottom;
  }
`;

export default CraftedByMits;
