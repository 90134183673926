import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { forScreenAtMost680pxWide } from '../../../styles/mediaQueries';

interface CommonTypographyProps {
  $uppercase?: boolean;
  $textAlign?: 'right' | 'center' | 'left';
  $fontWeight?: '400' | '700';
  $breakWord?: boolean;
}

interface TypographyWithColorProps extends CommonTypographyProps {
  $color: keyof DefaultTheme['colors']['texts'];
}

interface TypographyWithGradientProps extends CommonTypographyProps {
  $gradient: keyof DefaultTheme['gradients'];
}

export type TypographyProps =
  | TypographyWithColorProps
  | TypographyWithGradientProps;

const colorTextStyles = (
  color: TypographyWithColorProps['$color'],
): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
  css`
    color: ${({ theme }) => theme.colors.texts[color]};
  `;

export const gradientTextStyles = (
  gradient: TypographyWithGradientProps['$gradient'],
): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
  css`
    background: ${({ theme }) => theme.gradients[gradient]};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  `;

const SharedTypographyStyles = css<CommonTypographyProps>`
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
  word-break: ${({ $breakWord }) => ($breakWord ? 'break-word' : 'normal')};
  margin: 0;
`;

const BaseTitle = styled('h1')<TypographyProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '700'};
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.05em;
  ${SharedTypographyStyles}

  ${forScreenAtMost680pxWide(css`
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.03em;
  `)}
`;

export const Title = styled(BaseTitle)<TypographyWithColorProps>`
  ${({ $color }) => colorTextStyles($color)};
`;

export const GradientTitle = styled(BaseTitle)<TypographyWithGradientProps>`
  ${({ $gradient }) => gradientTextStyles($gradient)}
`;

export const BaseHeader1 = styled('h1')`
  font-weight: ${({ $fontWeight }) => $fontWeight || '700'};
  font-size: 38px;
  line-height: 44px;
  letter-spacing: -0.03em;
  ${SharedTypographyStyles}

  ${forScreenAtMost680pxWide(css`
    font-size: 36px;
    line-height: 38px;
  `)}
`;

export const GradientHeader1 = styled(BaseHeader1)<TypographyWithGradientProps>`
  ${({ $gradient }) => gradientTextStyles($gradient)}
`;

export const Header1 = styled(BaseHeader1)<TypographyWithColorProps>`
  ${({ $color }) => colorTextStyles($color)};
`;

export const GradientHeader = styled('h2')<TypographyWithGradientProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '700'};
  font-size: 38px;
  line-height: 44px;
  letter-spacing: -0.03em;
  ${({ $gradient }) => gradientTextStyles($gradient)}
  ${SharedTypographyStyles}
`;

export const Header = styled('h2')<TypographyWithColorProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '700'};
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.03em;
  ${({ $color }) => colorTextStyles($color)};
  ${SharedTypographyStyles}

  ${forScreenAtMost680pxWide(css`
    font-size: 22px;
    line-height: 26.4px;
  `)}
`;

export const BaseHeader3Styles = css<CommonTypographyProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '700'};
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  ${SharedTypographyStyles}

  ${forScreenAtMost680pxWide(css`
    font-size: 18px;
    line-height: 22px;
  `)}
`;

export const BaseHeader3 = styled('h3')`
  ${BaseHeader3Styles}
`;

export const Header2 = Header; // alias

export const Header3 = styled(BaseHeader3)<TypographyWithColorProps>`
  ${({ $color }) => colorTextStyles($color)};
`;

export const BaseHeader6Styles = css<CommonTypographyProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '700'};
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: -0.03em;
  ${SharedTypographyStyles}
`;

export const Header6 = styled('h6')<TypographyWithColorProps>`
  ${BaseHeader6Styles}
  ${({ $color }) => colorTextStyles($color)};
`;

export const Upheader = styled('span')<TypographyWithColorProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '400'};
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5em;
  display: block;
  ${({ $color }) => colorTextStyles($color)};
  ${SharedTypographyStyles}
  text-transform: uppercase;
`;

export const TextLarge = styled('p')<TypographyWithColorProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '400'};
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0;
  ${({ $color }) => colorTextStyles($color)};
  ${SharedTypographyStyles}
`;

export const BaseTextMediumStyles = css<CommonTypographyProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '400'};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  ${SharedTypographyStyles}
`;

export const TextMedium = styled('p')<TypographyWithColorProps>`
  ${BaseTextMediumStyles}
  ${({ $color }) => colorTextStyles($color)};
`;

export const TextSmall = styled('p')<TypographyWithColorProps>`
  font-weight: ${({ $fontWeight }) => $fontWeight || '400'};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;
  ${({ $color }) => colorTextStyles($color)};
  ${SharedTypographyStyles}
`;
