import 'normalize.css';
import React from 'react';
import routes from '../../routes';
import styled, { css, DefaultTheme, ThemeProvider } from 'styled-components';
import GlobalStyles from '../../styles/GlobalStyles';
import { forScreenAtMost1200pxWide } from '../../styles/mediaQueries';
import { greenTheme } from '../../styles/themes';
import MetaTags from '../atoms/MetaTags';
import Footer, { FooterBoxContent } from './Footer';
import TopNavigation from './TopNavigation';
import { useLocation } from '@reach/router';

interface LayoutProps {
  title: string;
  description?: string;
  footerBoxContent: FooterBoxContent;
  textColor?: keyof DefaultTheme['colors']['texts'];
  backgroundColor?: keyof DefaultTheme['colors']['backgrounds'];
  ogImage?: string;
  topNavigation?: React.ReactElement;
  children: React.ReactElement | React.ReactElement[] | string;
}

const Layout = ({
  title,
  footerBoxContent,
  textColor = 'primary',
  backgroundColor = 'primary',
  topNavigation,
  description,
  ogImage,
  children,
}: LayoutProps): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <ThemeProvider theme={greenTheme}>
      <MetaTags description={description} ogImage={ogImage} title={title} />
      <GlobalStyles backgroundColor={backgroundColor} textColor={textColor} />
      {topNavigation ?? (
        <TopNavigation
          initialWhiteBackground={[
            routes.newsroom,
            routes.grants,
            routes.announcements,
            routes.contactUs,
            routes.privacyPolicy,
            routes.cookiesPolicy,
          ].includes(pathname)}
        />
      )}
      {children}
      <Footer boxContent={footerBoxContent} />
    </ThemeProvider>
  );
};

export const MainColumnWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const MainContentColumnWrapper = styled.div`
  width: 100%;
  max-width: 1134px;
  margin: 0 auto;

  ${forScreenAtMost1200pxWide(css`
    max-width: 100%;
    padding: 0 30px;
  `)}
`;

export default Layout;
